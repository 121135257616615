import Menu from "./menu";
import { computePosition, arrow } from "@floating-ui/dom";

export default class HoverMenu extends Menu {
  constructor() {
    super(...arguments);

    this.timeouts = {};
  }

  setupEventListeners() {
    for (let trigger of this.triggers) {
      trigger.addEventListener("mouseenter", (event) =>
        this.triggerEntered(trigger, event)
      );
      trigger.addEventListener("mouseleave", (event) =>
        this.triggerLeft(trigger, event)
      );
    }

    for (let menu of this.menus) {
      menu.addEventListener("mouseenter", (event) =>
        this.menuEntered(menu, event)
      );
      menu.addEventListener("mouseleave", (event) =>
        this.menuLeft(menu, event)
      );
    }
  }

  triggerEntered(trigger) {
    let menu = this.menuForTrigger(trigger);
    this.openMenu(menu);
  }

  triggerLeft(trigger) {
    let menu = this.menuForTrigger(trigger);
    this.closeMenu(menu);
  }

  menuEntered(menu) {
    this.openMenu(menu);
  }

  menuLeft(menu) {
    this.closeMenu(menu);
  }

  openMenu(menu) {
    if (this.timeouts[menu.id]) {
      clearTimeout(this.timeouts[menu.id]);
    }

    let trigger = this.triggerForMenu({ id: menu.id });

    this.positionMenu(menu);
    super.openMenu(menu);
  }

  async positionMenu(menu) {
    let trigger = this.triggerForMenu(menu);
    let arrowElement = menu.querySelector(".arrow");

    let {
      x: menuX,
      y: menuY,
      middlewareData: {
        arrow: { x: arrowX, y: arrowY },
      },
    } = await computePosition(trigger, menu, {
      placement: "bottom",
      middleware: [arrow({ element: arrowElement })],
    });

    Object.assign(menu.style, {
      top: `${menuY}px`,
      left: `${menuX}px`,
    });

    Object.assign(arrowElement.style, {
      left: `${arrowX}px`,
      top: `${arrowY}px`,
    });
  }

  closeMenu(menu) {
    if (this.timeouts[menu.id]) {
      clearTimeout(this.timeouts[menu.id]);
    }

    this.timeouts[menu.id] = setTimeout(() => super.closeMenu(menu), 100);
  }
}
