class AnchorButton {
  constructor(parent) {
    this.parent = parent;
    this.template = document.querySelector("#anchor-button");

    this.button = document.createElement("a");
    this.button.href = `#${this.parent.id}`;
    this.button.classList.add("anchor-button");
    this.button.appendChild(this.template.content.cloneNode(true));

    this.button.addEventListener("click", () => {
      navigator.clipboard.writeText(this.button.href);
    });

    parent.appendChild(this.button);
  }
}

export default class Anchors {
  constructor(selector = "h1[id], h2[id], h3[id], h4[id], h5[id], h6[id]") {
    this.elements = document.querySelectorAll(selector);

    this.elements.forEach((element) => {
      new AnchorButton(element);
    });
  }
}
