export default class UrlState {
  static state = new UrlState();

  static get() {
    return this.state.get(...arguments);
  }

  static set() {
    return this.state.set(...arguments);
  }

  static addEventListener() {
    return this.state.addEventListener(...arguments);
  }

  static removeEventListener() {
    return this.state.removeEventListener(...arguments);
  }

  constructor() {
    this.eventListeners = [];

    window.addEventListener("popstate", () => {
      this.notifyChange();
    });
  }

  addEventListener(event, callback) {
    if (event === "change") {
      this.eventListeners.push(callback);
    }
  }

  removeEventListener(event, callback) {
    if (event === "change") {
      this.eventListeners = this.eventListeners.filter(
        (listener) => listener !== callback
      );
    }
  }

  notifyChange() {
    this.eventListeners.forEach((callback) => callback(this));
  }

  get urlParams() {
    return new URLSearchParams(window.location.search);
  }

  get hash() {
    return window.location.hash;
  }

  get path() {
    return window.location.pathname;
  }

  get(key) {
    return this.urlParams.get(key);
  }

  set(key, value) {
    let { path, urlParams, hash } = this;

    urlParams.set(key, value);

    window.history.replaceState({}, "", `${path}?${urlParams}${hash}`);

    this.notifyChange();
  }
}

const urlState = new UrlState();
