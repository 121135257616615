export default class TableOfContents {
  constructor(element) {
    this.element = element;

    window.addEventListener("scroll", () => this.update());

    this.update();
  }

  get headlines() {
    return this.links.map((link) => document.querySelector(link.hash));
  }

  get links() {
    return Array.from(this.element.querySelectorAll("a"));
  }

  update() {
    let current = null;

    this.headlines.forEach((headline) => {
      let bounds = headline.getBoundingClientRect();

      let scrollMarginTop = getComputedStyle(headline).scrollMarginTop;
      let offset = (parseInt(scrollMarginTop, 10) ?? 0) + 30;

      let link = this.element.querySelector(`a[href="#${headline.id}"]`);
      let item = link.parentElement;

      if (bounds.top < offset) {
        item.classList.add("viewed", "current");
        current?.classList.remove("current");
        current = item;
      } else {
        item.classList.remove("viewed", "current");
      }
    });
  }
}
