import UrlState from "./url-state.js";

function wait(timeout) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export default class Example {
  constructor(element) {
    this.element = element;

    this.copyButton?.addEventListener("click", () => {
      this.copyToClipboard();
    });

    this.select?.addEventListener("change", (event) => {
      let value = event.target.value;
      let offset = element.offsetTop - window.scrollY;

      UrlState.set("language", value);

      window.scrollTo({
        top: element.offsetTop - offset,
        behavior: "instant",
      });
    });

    UrlState.addEventListener("change", () => {
      let value = UrlState.get("language");

      this.updateVariant(value);
    });

    let initialValue = UrlState.get("language");

    this.updateVariant(initialValue || this.defaultVariant);
  }

  updateVariant(value) {
    if (this.variants.indexOf(value) < 0) {
      value = this.defaultVariant;
    }

    if (this.select) {
      this.select.value = value;
    }

    this.elements.forEach((element) => {
      element.ariaHidden = element.dataset.variant !== value;
    });
  }

  async copyToClipboard() {
    let example = this.elements.find(
      (element) => element.ariaHidden === "false"
    );

    let code = example.querySelector(".rouge-code");
    code ??= example.querySelector("code");

    await navigator.clipboard.writeText(code.innerText);

    this.copyButton.classList.add("success");
    await wait(1000);
    this.copyButton.classList.remove("success");
  }

  get elements() {
    return Array.from(this.element.querySelectorAll(".code-variant"));
  }

  get variants() {
    return this.elements.map((element) => element.dataset.variant);
  }

  get defaultVariant() {
    return this.variants[0];
  }

  get select() {
    return this.element.querySelector("select");
  }

  get copyButton() {
    return this.element.querySelector(".copy-button");
  }
}
