import Notification from "./components/notification";
import PricingTable from "./components/pricing-table";
import PricingSlider from "./components/pricing-slider";
import CookieConsent from "./components/cookie-consent";
import Transitions from "./components/transitions";
import Menu from "./components/menu";
import HoverMenu from "./components/hover-menu";
import Search from "./components/search";
import TableOfContents from "./components/table-of-contents";
import Header from "./components/header";
import Scroller from "./components/scroller";
import Anchors from "./components/anchors";
import Code from "./components/code";

window.addEventListener("DOMContentLoaded", function () {
  // Sticky header
  new Header("body > header");

  // Top navigation menu
  new HoverMenu("header nav.desktop");

  // Sidebar navigation menu
  new Menu(".sidebar nav");

  // Mobile top navigation menu
  new Menu("header nav.mobile", ".toggle", { exclusive: true });

  // Mobile expanded navigation menu
  new Menu("#mobile-menu");

  // Pricing
  var pricingSections = document.querySelectorAll("section.pricing");
  for (let pricingSection of pricingSections) {
    new PricingTable(pricingSection);
  }

  // Notifications
  var notifications = document.querySelectorAll(".notification[data-hash]");
  for (let notification of notifications) {
    new Notification(notification);
  }

  // Scroller
  var scrollers = document.querySelectorAll("[data-scroller]");
  for (let scroller of scrollers) {
    new Scroller(scroller);
  }

  // Anchors
  new Anchors(
    ".docs article h1[id], .docs article h2[id], .docs article h3[id], .docs article h4[id], .docs article h5[id], .docs article h6[id]"
  );

  // Examples
  var codes = document.querySelectorAll(".code");
  for (let code of codes) {
    new Code(code);
  }

  // Search
  new Search("main .search", "/search.json");
  new Search("header .search", "/search.json");

  // Cookie Consent
  new CookieConsent();

  // Transitions
  new Transitions();

  // Table of Contents
  var tocs = document.querySelectorAll(".table-of-contents");
  for (let toc of tocs) {
    new TableOfContents(toc);
  }
});
